import { useSessionStorage } from 'react-use';
import clsx from 'clsx';
import type { ReactElement } from 'react';
import type { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';

import { Icon } from '@/components/icon';
import { StructuredText } from '@/components/structured-text';

import { Close, Content, PreContent, Wrapper } from './styles.css';
import { Button } from '../button/styles.css';

const AnnouncementBar = ({
  enabled,
  content,
}: {
  enabled: boolean;
  content: StructuredTextType | null | undefined;
}): ReactElement | null => {
  const [dismissed, setDismissed] = useSessionStorage('announcement-dismissed', false);
  if (!enabled || dismissed) {
    return null;
  }

  return (
    <div className="col-12" data-datocms-noindex>
      <div className={Wrapper}>
        <div className={PreContent} />
        <div className={Content}>
          <StructuredText data={content as StructuredTextType} />
        </div>
        <button
          type="button"
          aria-label="announcement"
          aria-expanded
          className={clsx([Button({ size: 'sm', color: 'transparent' }), Close])}
          onClick={() => setDismissed(true)}
        >
          <Icon className="fa-md" name="times" />
        </button>
      </div>
    </div>
  );
};

export { AnnouncementBar };
