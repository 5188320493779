import { FC, MouseEvent, MouseEventHandler, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { isFunction, isObject, isString } from 'lodash';
import NextLink from 'next/link';
import type { LinkProps as NextLinkProps } from 'next/link';

import { URLS, RACT_WEBSITE_REGEX, RACT_SUB_DOMAIN_REGEX, RACT_EXTERNAL_DOMAINS } from '@/lib/constants';
import { useAnalytics } from '@/lib/analytics';

type LinkAdditionalProps = {
  className?: string;
  role?: string;
  title?: string;
  target?: string;
  analytics?: { [key: string]: string | null };
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};
type LinkProps = PropsWithChildren<NextLinkProps> & LinkAdditionalProps;

const getAnalyticsText = (children: ReactNode): string => {
  if (children && isString(children)) {
    return children;
  }

  if (Array.isArray(children) && isString(children[0])) {
    return children[0];
  }

  return 'Unknown';
};

const Link: FC<LinkProps> = ({
  analytics = {},
  children,
  href: rawHref,
  onClick = undefined,
  passHref = true,
  prefetch = false,
  replace = undefined,
  scroll = undefined,
  shallow = undefined,
  ...props
}): ReactElement => {
  const { track } = useAnalytics();

  // Sometimes content editors can accidentally add absolute links. We want to ensure
  // website visitors don't trigger a new page load so we transform it.

  const isAbsoluteInternalLink = isString(rawHref) && rawHref.match(RACT_WEBSITE_REGEX) !== null;

  // Here we clean off the absolute part of an internal link to make it relative.
  const href = isAbsoluteInternalLink ? String(rawHref).replace(RACT_WEBSITE_REGEX, '/') : rawHref;

  const isRelativeLink = String(href).startsWith('/') || String(href).startsWith('#') || isObject(href);

  const isExternalLink =
    // External links will never be relative
    !isRelativeLink &&
    // External links will not match a subdomain
    String(href).match(RACT_SUB_DOMAIN_REGEX) === null &&
    // External links will not start with the domain of ones we classify as belonging to RACT
    RACT_EXTERNAL_DOMAINS.some((externalDomain) => !String(href).startsWith(externalDomain));

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (event: MouseEvent<HTMLAnchorElement>) => {
    if (isFunction(onClick)) onClick(event);

    track('Link Clicked', {
      href: isRelativeLink ? `${URLS.RACT_WEBSITE}${href}` : href,
      context: 'Unknown',
      path: isRelativeLink ? href : undefined,
      text: getAnalyticsText(children),
      isExternal: isExternalLink,
      ...analytics,
    });
  };

  const onAuxClick: MouseEventHandler<HTMLAnchorElement> = (event: MouseEvent<HTMLAnchorElement>) => {
    // Check if middle click
    if (event?.button === 1) handleOnClick(event);
  };

  const clickEvents = {
    onClick: handleOnClick,
    onAuxClick,
  };

  // If a href does not start with `http` or is an object, handle it with NextLink
  if (isRelativeLink) {
    return (
      <NextLink href={href} replace={replace} scroll={scroll} shallow={shallow} passHref={passHref} prefetch={prefetch}>
        {/* eslint-disable-next-line react/forbid-elements,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
        <a {...clickEvents} {...props}>
          {children}
        </a>
      </NextLink>
    );
  }

  return (
    // eslint-disable-next-line react/forbid-elements, react/jsx-no-target-blank
    <a
      href={String(href)}
      target={isExternalLink ? '_blank' : undefined}
      rel={isExternalLink ? 'noreferrer' : undefined}
      {...clickEvents}
      {...props}
    >
      {children}
    </a>
  );
};

export { Link };
