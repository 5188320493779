/* eslint-disable react/require-default-props */

import { Image as DatoImage } from 'react-datocms';
import clsx from 'clsx';
import type { ReactElement } from 'react';
import type { ResponsiveImageType } from 'react-datocms';

import { BackgroundImageStyle, ImageStyle, FullWidthContainer, FullWidthImage } from './styles.css';

type ImagePropTypes = {
  data?: null | ResponsiveImageType;
  className?: string;
  pictureClassName?: string;
  fadeInDuration?: number;
  intersectionThreshold?: number;
  intersectionMargin?: string;
  lazyLoad?: boolean;
  style?: React.CSSProperties;
  pictureStyle?: React.CSSProperties;
  explicitWidth?: boolean;
  focalPoint?: {
    x: number;
    y: number;
  };
  isBackground?: boolean;
  shouldHideBackground?: boolean;
};

const Image = ({
  className,
  data = null,
  fadeInDuration = 300,
  intersectionMargin = '200px 0px 0px 0px',
  isBackground = false,
  shouldHideBackground,
  ...props
}: ImagePropTypes): ReactElement | null => {
  // the presence of the data prop signifies the image has come from datoCMS
  // In these instances we use the datoCMS Image component
  // For usage see: https://www.datocms.com/docs/next-js/managing-images
  if (data) {
    const objectPosition = props.focalPoint ? `${props.focalPoint.x * 100}% ${props.focalPoint.y * 100}%` : '50% 50%';
    return (
      <div
        className={clsx(
          { [BackgroundImageStyle]: isBackground },
          className,
          shouldHideBackground && FullWidthContainer
        )}
      >
        <DatoImage
          {...props}
          fadeInDuration={fadeInDuration}
          intersectionMargin={intersectionMargin}
          className={clsx(FullWidthImage, ImageStyle, { [BackgroundImageStyle]: isBackground })}
          data={data}
          pictureStyle={
            isBackground
              ? {
                  objectFit: 'cover',
                  objectPosition,
                }
              : undefined
          }
        />
      </div>
    );
  }

  return null;
};

export { Image };
