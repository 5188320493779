import { StructuredText as DatoStructuredText, renderNodeRule } from 'react-datocms';
import { isLink, isList, isListItem } from 'datocms-structured-text-utils';
import { isString, kebabCase } from 'lodash';
import clsx from 'clsx';
import type { ReactElement } from 'react';
import type { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';

import { captureMessage } from '@/utils/sentry';
import { Colors } from '@/lib/constants';
import { ColourStrings, ContentBlockType, ModelLink } from '@/lib/types';
import { getModelURL } from '@/lib/datocms/utils/get-model-url';
import { isStructuredTextEmpty } from '@/lib/datocms/utils/is-structured-text-empty';
import { Link } from '@/components/link';
import { notify } from '@/utils/notify';
import { getRenderedBlock } from './utils/get-rendered-block';
import { DEFAULT_ICON, ICON_MARKUP_REGEX, ICON_REMOVE_WRAPPER_REGEX } from './constants';

type Node = {
  value?: string;
  children?: Node[];
};

// Method determines if there are any icons within a whole list, if there is
// even 1 icon then it is treated as a "icon list"
const getIsIconList = (listNode: Node): boolean =>
  Boolean(
    listNode.children?.some((listItem) =>
      listItem.children?.some((paragraph) =>
        paragraph.children?.some((span) =>
          // Using .match instead of test because of how "test" behaves on multiple executions
          // See https://javascript.plainenglish.io/most-surprising-behavior-of-javascript-regular-expression-you-have-ever-seen-1ddb84539163
          isString(span.value) ? span.value.match(ICON_MARKUP_REGEX) !== null : false
        )
      )
    )
  );

const LINK_ANALYTICS_CONTEXT = 'Inline Link';

const StructuredText = ({ data }: { data: StructuredTextType }): ReactElement | null => {
  if (isStructuredTextEmpty(data)) {
    return null;
  }

  return (
    <div className="rich-text">
      <DatoStructuredText
        data={data}
        renderInlineRecord={({ record }) => {
          captureMessage('Inline record not allowed/rendered for structured text', { extra: { record } });
          return null;
        }}
        renderLinkToRecord={({ record, children }): ReactElement => (
          <Link
            href={getModelURL(record as unknown as ModelLink)}
            analytics={{
              context: LINK_ANALYTICS_CONTEXT,
            }}
          >
            {children}
          </Link>
        )}
        renderBlock={({ record }) => getRenderedBlock(record as unknown as ContentBlockType, { isInline: true })}
        customNodeRules={[
          renderNodeRule(isLink, ({ node, key, children = [] }) => {
            if (node.url.match(/(\/\/|\/\/www\.|@)ract.com.au/gi)) {
              notify(`Internal link incorrectly linked: ${node.url}`, 'error', { id: `${key}:${node.url}` });
            }

            return children?.length > 0 ? (
              <Link
                key={key}
                href={node.url}
                analytics={{
                  context: LINK_ANALYTICS_CONTEXT,
                }}
              >
                {children[0]}
              </Link>
            ) : null;
          }),
          renderNodeRule(isList, ({ node, key, children }) => {
            const className = getIsIconList(node as Node) ? 'fa-ul' : '';
            if (node.style === 'bulleted') {
              return (
                <ul className={className} key={key}>
                  {children}
                </ul>
              );
            }
            return (
              <ol className={className} key={key}>
                {children}
              </ol>
            );
          }),
          renderNodeRule(isListItem, ({ key, children, ancestors }) => {
            const isInListWithIcons = ancestors && ancestors.length ? getIsIconList(ancestors[0] as Node) : false;

            if (isInListWithIcons) {
              const [listItem] = children as ReactElement[];

              const [listItemIcon, ...listItemContent] = listItem.props?.children as ReactElement[];

              const foundIcons = listItemIcon.props?.children[0].match(ICON_MARKUP_REGEX) || [];

              const icon = foundIcons[0]?.replace(ICON_REMOVE_WRAPPER_REGEX, '');

              const [iconName, color] = isString(icon) ? icon.split('|') : [];
              // If it's a hex color string use that with style prop
              const style = (color || '').startsWith('#') ? { color } : {};
              // If it's a defined brand colour use that css class instead
              const iconColor = Object.values(Colors).includes(color as ColourStrings)
                ? `text-${kebabCase(color)}`
                : null;

              return (
                <li key={key}>
                  <span className="fa-li">
                    <em className={clsx('fal', `fa-${iconName || DEFAULT_ICON}`, iconColor)} style={style} />
                  </span>
                  {/* in the event no icon was used, we need to render the first child element */}
                  {listItemIcon.props?.children[0].replace(ICON_MARKUP_REGEX, '')}
                  {listItemContent}
                </li>
              );
            }

            return <li key={key}>{children}</li>;
          }),
        ]}
      />
    </div>
  );
};

export { StructuredText };
